<template>
  <div>
    <NuxtImg
      v-if="isMobile && mobileImage"
      :src="mobileImage"
      :loading="loading"
      :preload="preload ? { fetchPriority: 'high' } : { fetchPriority: 'low' }"
      class="w-full h-full object-cover"
    />
    <NuxtImg
      v-else
      :src="desktopImage"
      :loading="loading"
      :preload="preload ? { fetchPriority: 'high' } : { fetchPriority: 'low' }"
      class="w-full h-full object-cover"
      :class="autoHeight ? 'lg:h-auto' : ''"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  desktopImage: String,
  mobileImage: String,
  preload: Boolean,
  loading: String,
  autoHeight: {
    type: Boolean,
    default: true,
  },
})

const { isMobile } = useDevice()
</script>
